<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_report') || isAdmin()"
      wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-daterange
                    v-model="range"
                    :presets="presets"
                    :input-props="inputProps"
                    :locale="$i18n.locale"
                    :start-label="$t('evoucher.issuance.startDate')"
                    :end-label="$t('evoucher.issuance.endDate')"
                    :menu-props="menuProps"
                    :separator-label="$t('evoucher.to')"
                    :min="minDate"
                    :max="maxDate"
                    display-format="dd/MM/yyyy"
                    @input="onDateRangeChange"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    v-model="voucherStatus"
                    :items="eVoucherAssignedStatusList"
                    :label="$t('evoucher.issuance.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="voucherName"
                    :label="$t('evoucher.issuance.workpiece')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="supplierName"
                    :label="$t('evoucher.issuance.supplier')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('voucherReport.statisticalCharts')"
          color="green"
          flat
          full-width
        >
          <v-form>
            <v-container py-0>
              <v-layout
                wrap
              >
                <v-flex
                  xs12
                  sm4
                  md4
                >
                  <v-select
                    v-model="unitType"
                    :items="unitList"
                    :label="$t('evoucher.issuance.type')"
                    @change="onReportChartTypeChange"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4 />
                <v-flex
                  xs12
                  sm4
                  md4/>
              </v-layout>
            </v-container>
          </v-form>
          <chartist
            v-show="unitType == 1"
            :data="data"
            :options="quantityOptions"
            type="Line" />
          <chartist
            v-show="unitType == 2"
            :data="data"
            :options="priceOptions"
            data-y-axis="VNĐ"
            type="Line" />
        </material-card>
        <material-card
          :title="$t('voucherReport.statisticalTables')"
          color="green"
          flat
          full-width>
          <v-container py-0>
            <v-layout
              wrap
            >
              <v-flex
                xs12
                sm6
                md6
                px-0
              >
                <v-select
                  v-model="selectedHeaders"
                  :items="headers"
                  :label="$t('voucherReport.selectColumn')"
                  :item-text="text"
                  multiple
                  outlined
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 4"
                      class="grey--text caption">(+{{ selectedHeaders.length - 4 }} {{ $t('voucherReport.others') }})</span>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-btn
            v-if="hasAction('export_report')"
            color="success"
            dark
            style="float: right;"
            @click="onExportToExcelFile"
          >
            <v-icon>mdi-file-export</v-icon>
            <span style="text-transform: none;">{{ $t('voucherReport.exportExcel') }}</span>
          </v-btn>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="showHeaders"
            :items="vouchers"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in showHeaders"
                :key="header.value + index">
                <span v-if="header.value == 'price'">{{ formatMoney(item[header.value]) }}</span>
                <span v-else-if="header.value == 'status'">{{ $t(getVoucherStatus(item[header.value])) }}</span>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import VDaterange from 'Components/VDaterange'
import { ISO_FORMAT } from 'utils/constants'
import { format, subDays, subMonths } from 'date-fns'
// import * as ChartistTooltips from 'chartist-plugin-tooltips';
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import VoucherReportType from 'enum/voucherReportType'
import XLSX from 'xlsx'
import EntityType from 'enum/entityType'
import ToastType from 'enum/toastType'
export default {
  components: {
    VDaterange
  },
  data: () => ({
    isLoading: false,
    range: {},
    inputProps: { solo: false, style: { width: '240px' } },
    menuProps: { offsetY: true, closeOnContentClick: false },
    presets: [
      {
        label: 'rangeCalendar.today',
        range: [format(new Date(), ISO_FORMAT), format(new Date(), ISO_FORMAT)]
      },
      {
        label: 'rangeCalendar.yesterday',
        range: [
          format(subDays(new Date(), 1), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.last7Days',
        range: [
          format(subDays(new Date(), 7), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastWeek',
        range: [
          format(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            ISO_FORMAT
          ),
          format(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.last30Days',
        range: [
          format(subDays(new Date(), 30), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.currentMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            ISO_FORMAT
          )
        ]
      }
    ],
    minDate: format(subMonths(new Date(), 13), ISO_FORMAT),
    maxDate: format(new Date(), ISO_FORMAT),
    data: {
      labels: [''],
      series: [[{
        meta: '', value: null
      }]]
    },
    quantityOptions: {
      height: 500,
      fullWidth: true,
      chartPadding: {
        right: 40,
        left: 30
      },
      plugins: [ChartistTooltip({ appendToBody: true })], // End of plugins
      axisY: {
        offset: 10,
        position: 'start',
        labelInterpolationFnc: function (value) {
          return Math.round(value)
        }
      }
    }, // End of options
    priceOptions: {
      height: 500,
      fullWidth: true,
      chartPadding: {
        right: 40,
        left: 60
      },
      plugins: [ChartistTooltip({ appendToBody: true,
        transformTooltipTextFnc: function (tooltip) {
          return functionUtils.convertFormatNumberToMoney(tooltip)
        } })], // End of plugins
      axisY: {
        offset: 10,
        position: 'start',
        labelInterpolationFnc: function (value) {
          return functionUtils.convertFormatNumberToMoney(value)
        }
      }
    },
    unitList: [
      { text: 'evoucher.quantity', value: 1 },
      { text: 'evoucher.issuance.price', value: 2 }
    ],
    unitType: 1,
    dateArray: [],
    quantityData: [],
    priceData: [],
    eVoucherAssignedStatusList: [{ text: '---', value: null }, ...stringUtils.EVoucherReportStatus],
    voucherStatus: null,
    headers: [],
    headersMap: {
      stt: { text: 'common.nonumber', value: 'stt' },
      workpieceCode: { text: 'evoucher.issuance.code', value: 'workpieceCode' },
      voucherName: { text: 'evoucher.issuance.workpiece', value: 'voucherName' },
      discount: { text: 'evoucher.discount', value: 'discount' },
      price: { text: 'evoucher.issuance.pricePercent', value: 'price' },
      status: { text: 'evoucher.issuance.status', value: 'status', sortable: false }
      // voucherSerial: { text: 'evoucher.voucherSerial', value: 'voucherSerial' }
    },
    selectedHeaders: [],
    vouchers: [],
    voucherName: null,
    supplierName: null,
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    }
  }),
  computed: {
    // Done to get the ordered headers
    showHeaders () {
      return this.headers.filter(s => this.selectedHeaders.includes(s))
    },
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
  },
  created () {
    this.headers = Object.values(this.headersMap)
    this.selectedHeaders = this.headers
    if (this.isEntityType(EntityType.SUPPLIER)) {
      this.minDate = null
    }
  },
  methods: {
    /**
     * Check entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    // Text selection column
    text (item) {
      return this.$t(item.text)
    },
    /**
     * Get voucher status
     */
    getVoucherStatus (status) {
      return functionUtils.getVoucherStatusText(status)
    },
    // Clone object
    clone: function (obj) {
      return Object.assign({}, obj)
    },
    // Rename key
    renameKey: function (object, key, newKey) {
      const targetKey = object[key]
      delete object[key]
      object[newKey] = targetKey
      return object
    },
    /**
     * Export to excel file
     */
    onExportToExcelFile: function () {
      let filterTable = {
        params: {
          startDate: this.range.start,
          endDate: this.range.end,
          voucherStatus: this.voucherStatus,
          voucherReportType: VoucherReportType.TABLE,
          supplierName: this.supplierName ? this.supplierName.trim() : null,
          voucherName: this.voucherName ? this.voucherName.trim() : null
        }
      }
      this.isLoading = true
      this.DETAIL_VOUCHER_STATISTICS(filterTable).then(
        function (res) {
          let data = res.data
          let vouchers = []
          for (let i = 0, size = data.length; i < size; i++) {
            let voucherObj = {
              stt: i + 1,
              workpieceCode: data[i].workpieceCode,
              discount: data[i].discount,
              voucherName: data[i].voucherName,
              price: data[i].price,
              status: data[i].status
            }
            vouchers.push(voucherObj)
          }
          let newData = []
          let obj = {}
          // Get key customize (Only show keys selected)
          for (let i = 0, size = vouchers.length; i < size; i++) {
            for (var property in vouchers[i]) {
              for (let j = 0, headersSize = this.selectedHeaders.length; j < headersSize; j++) {
                if (this.selectedHeaders[j].value === property) {
                  if (property === 'status') {
                    obj[property] = this.$t(this.getVoucherStatus(vouchers[i][property]))
                  } else {
                    obj[property] = vouchers[i][property]
                  }
                }
              }
            }
            newData.push(obj)
            obj = {}
          }
          // Convert code to text
          for (let k = 0, size3 = newData.length; k < size3; k++) {
            for (var property2 in newData[k]) {
              if (property2 === 'discount') {
                this.renameKey(newData[k], property2, this.$t('evoucher.discount'))
              }
              if (property2 === 'workpieceCode') {
                this.renameKey(newData[k], property2, this.$t('evoucher.issuance.code'))
              }
              if (property2 === 'voucherName') {
                this.renameKey(newData[k], property2, this.$t('evoucher.issuance.workpiece'))
              }
              if (property2 === 'price') {
                this.renameKey(newData[k], property2, this.$t('evoucher.issuance.price'))
              }
              if (property2 === 'status') {
                this.renameKey(newData[k], property2, this.$t('evoucher.issuance.status'))
              }
              if (property2 === 'stt') {
                this.renameKey(newData[k], property2, this.$t('common.nonumber'))
              }
              // if (property2 === 'voucherSerial') {
              //   this.renameKey(data[k], property2, this.$t('evoucher.voucherSerial'))
              // }
            }
          }
          // export json to Worksheet of Excel
          // only array possible
          var vouchersReport = XLSX.utils.json_to_sheet(newData)
          // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)
          // A workbook is the name given to an Excel file
          var wb = XLSX.utils.book_new() // make Workbook of Excel
          // add Worksheet to Workbook
          // Workbook contains one or more worksheets
          XLSX.utils.book_append_sheet(wb, vouchersReport, this.$t('voucherReport.title')) // sheetAName is name of Worksheet
          // export Excel file
          XLSX.writeFile(wb, this.$t('voucherReport.title') + ' ' + this.$t('evoucher.from') + ' ' + this.$t(this.range.start) + ' ' + this.$t('evoucher.to') + ' ' + this.$t(this.range.end) + '.xlsx') // name of the file is 'book.xlsx'
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Format integer to money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Report chart type
     */
    onReportChartTypeChange: function () {
      if (this.unitType === 2) {
        this.data.series = [
          this.priceData
        ]
      } else {
        this.data.series = [
          this.quantityData
        ]
      }
    },
    /**
     * Search
     */
    onSearch: function () {
      let filterChart = {
        params: {
          dateArray: this.dateArray,
          voucherStatus: this.voucherStatus,
          voucherReportType: VoucherReportType.CHART,
          supplierName: this.supplierName ? this.supplierName.trim() : null,
          voucherName: this.voucherName ? this.voucherName.trim() : null
        }
      }
      this.isLoading = true
      this.DETAIL_VOUCHER_STATISTICS(filterChart).then(
        function (res) {
          let data = res.data
          this.data.labels = []
          this.quantityData = []
          this.priceData = []
          for (let i = 0, size = data.length; i < size; i++) {
            this.data.labels.push(data[i].date)
            this.quantityData.push(data[i].quantity)
            this.priceData.push(data[i].total_price)
          }
          if (this.unitType === 1) {
            this.data.series = [
              this.quantityData
            ]
          } else {
            this.data.series = [
              this.priceData
            ]
          }
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
      this.getDetailVoucherStatisticsForTable()
    },
    /**
     * Get detail voucher statistics for table
     */
    getDetailVoucherStatisticsForTable: function () {
      let filterTable = {
        params: {
          startDate: this.range.start,
          endDate: this.range.end,
          voucherStatus: this.voucherStatus,
          voucherReportType: VoucherReportType.TABLE,
          supplierName: this.supplierName ? this.supplierName.trim() : null,
          voucherName: this.voucherName ? this.voucherName.trim() : null,
          page: this.paginate.currentPage
        }
      }
      this.isLoading = true
      this.DETAIL_VOUCHER_STATISTICS(filterTable).then(
        function (res) {
          let data = res.data
          this.paginate.currentPage = data.page
          this.paginate.totalPage = data.num_pages
          this.paginate.rowPerPage = data.per_page
          let results = data.results
          this.vouchers = []
          for (let i = 0, size = results.length; i < size; i++) {
            let voucherObj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              workpieceCode: results[i].workpieceCode,
              discount: results[i].discount,
              voucherName: results[i].voucherName,
              price: results[i].price,
              status: results[i].status
            }
            this.vouchers.push(voucherObj)
          }
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getDetailVoucherStatisticsForTable()
    },
    /**
     * Date range change
     */
    onDateRangeChange: function () {
      let dateArray = []
      dateArray = dateUtils.getDaysBetweenTwoDate(this.range.start, this.range.end, 'YYYY-MM-DD')
      this.dateArray = dateArray
    },
    ...mapActions([
      'DETAIL_VOUCHER_STATISTICS',
      'ON_SHOW_TOAST'
    ])
  } // End of methods
}
</script>

<style lang="scss">
html {
  overflow: hidden;
}
.bg-white {
  background-color: #fff !important;
}
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #a6dea8;
  color: #fff;
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #a6dea8;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}
[data-y-axis]::after {
    content: attr(data-y-axis);
    position: absolute;
    top: 50%;
    left: 0px;
    font-size: 11px;
    color: #777;
    text-align: center;
    transform: rotate(-90deg)translateY(50%);
}
</style>
